// utils/currency.js

export const formatCurrency = (number, currency = 'KZT') => {
    return new Intl.NumberFormat('ru-RU', {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 2  // Зависит от требований к точности
    }).format(number);
};

// utils/calculation.js
export const calculateTotal = (productPrice, deliveryFee) => {
    return productPrice + deliveryFee;
};

// utils/calculation.js
export const calculateCommission = (total, commissionRate = 0.05) => {
    return Math.round(total * commissionRate);
};