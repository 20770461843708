const COUNTRIES = [
    // СНГ (приоритетные страны)
    { value: "KAZAKHSTAN", label: "Казахстан" },
    { value: "RUSSIA", label: "Россия" },
    { value: "BELARUS", label: "Беларусь" },
    { value: "UKRAINE", label: "Украина" },
    { value: "AZERBAIJAN", label: "Азербайджан" },
    { value: "ARMENIA", label: "Армения" },
    { value: "GEORGIA", label: "Грузия" },
    { value: "KYRGYZSTAN", label: "Кыргызстан" },
    { value: "MOLDOVA", label: "Молдова" },
    { value: "TAJIKISTAN", label: "Таджикистан" },
    { value: "TURKMENISTAN", label: "Туркменистан" },
    { value: "UZBEKISTAN", label: "Узбекистан" },

    // Европейский Союз (ЕС)
    { value: "GERMANY", label: "Германия" },
    { value: "FRANCE", label: "Франция" },
    { value: "ITALY", label: "Италия" },
    { value: "SPAIN", label: "Испания" },
    { value: "POLAND", label: "Польша" },
    { value: "NETHERLANDS", label: "Нидерланды" },
    { value: "SWEDEN", label: "Швеция" },
    { value: "AUSTRIA", label: "Австрия" },
    { value: "BELGIUM", label: "Бельгия" },
    { value: "CZECHIA", label: "Чехия" },
    { value: "PORTUGAL", label: "Португалия" },
    { value: "FINLAND", label: "Финляндия" },
    { value: "GREECE", label: "Греция" },
    { value: "DENMARK", label: "Дания" },
    { value: "IRELAND", label: "Ирландия" },
    { value: "HUNGARY", label: "Венгрия" },
    { value: "SLOVAKIA", label: "Словакия" },
    { value: "SLOVENIA", label: "Словения" },
    { value: "LATVIA", label: "Латвия" },
    { value: "LITHUANIA", label: "Литва" },
    { value: "ESTONIA", label: "Эстония" },
    { value: "BULGARIA", label: "Болгария" },
    { value: "ROMANIA", label: "Румыния" },
    { value: "CROATIA", label: "Хорватия" },
    { value: "LUXEMBOURG", label: "Люксембург" },

    // Развитые страны (G7 и ключевые регионы)
    { value: "USA", label: "США" },
    { value: "CANADA", label: "Канада" },
    { value: "UNITED_KINGDOM", label: "Великобритания" },
    { value: "AUSTRALIA", label: "Австралия" },
    { value: "NEW_ZEALAND", label: "Новая Зеландия" },
    { value: "JAPAN", label: "Япония" },
    { value: "SOUTH_KOREA", label: "Южная Корея" },
    { value: "SINGAPORE", label: "Сингапур" },
    { value: "ISRAEL", label: "Израиль" },
    { value: "UNITED_ARAB_EMIRATES", label: "ОАЭ" },
    { value: "TURKEY", label: "Турция" }
];

export default COUNTRIES;  