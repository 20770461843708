import { useState, useEffect } from "react";

const CART_KEY = "my-cart";

export default function useCart() {
    const [cartItems, setCartItems] = useState([]);
    const inCartItems = cartItems.filter(item => item.status === "in_cart");
    const awaitingOrders = cartItems.filter(item => item.status === "awaiting");
    const paidOrders = cartItems.filter(item => item.status === "paid");

    // Загрузка из localStorage при инициализации
    useEffect(() => {
        const storedCart = localStorage.getItem(CART_KEY);
        if (storedCart) {
            try {
                setCartItems(JSON.parse(storedCart));
            } catch (e) {
                console.error("Ошибка парсинга корзины:", e);
            }
        }
    }, []);

    // Сохраняем при изменении
    useEffect(() => {
        localStorage.setItem(CART_KEY, JSON.stringify(cartItems));
    }, [cartItems]);

    const addToCart = (product, quantity = 1) => {
        setCartItems(prevItems => {
            const existingItem = prevItems.find(item => item.id === product.id);
            if (existingItem) {
                return prevItems.map(item =>
                    item.id === product.id
                        ? { ...item, quantity: item.quantity + quantity }
                        : item
                );
            } else {
                return [
                    ...prevItems,
                    {
                        id: product.id,
                        name: product.name,
                        price: product.price,
                        currency: product.currency,
                        city: product.city,
                        country: product.country,
                        image: product.image || product.currentMedia?.[0] || null,
                        quantity,
                        status: "in_cart" // новый ключ
                    }
                ];
            }
        });
    };

    const removeFromCart = (id) => {
        setCartItems(prevItems => prevItems.filter(item => item.id !== id));
    };

    const clearCart = () => {
        setCartItems([]);
    };

    const totalPrice = cartItems.reduce((sum, item) => sum + item.price * item.quantity, 0);
    const currency = cartItems[0]?.currency || '';

    return {
        cartItems,
        addToCart,
        removeFromCart,
        clearCart,
        totalPrice,
        currency
    };
}