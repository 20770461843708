import React from 'react';
import styles from './MessageBubble.module.css';

const MessageBubble = ({ text, from, time }) => {
    const formatTime = isoString => new Date(isoString).toLocaleTimeString('ru-RU', {
        hour: '2-digit',
        minute: '2-digit'
    });

    return (
        <div className={`${styles.message} ${styles[from]}`}>
            {text}
            <div className={styles.timeStamp}>{formatTime(time)}</div>
        </div>
    );
};

export default MessageBubble;
