// ChatButton.jsx
import React from 'react';
import styles from './ChatButton.module.css';
import { openChatInNewTab } from '../../utils/openChatInNewTab'; // Обновите путь в соответствии с вашей структурой

const ChatButton = ({ userId }) => {
    return (
        <button
            className={styles.chatButton}
            onClick={() => openChatInNewTab(userId)}
        >
            💬 Открыть чат
        </button>
    );
};

export default ChatButton; 