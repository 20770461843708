import React, { useState, useEffect } from "react";
import styles from "./Partners.module.css";
import chipImage from "../../assets/svg/Partners/chip.svg";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Partners = () => {
    const [partners, setPartners] = useState([]);
    const [partnerStatus, setPartnerStatus] = useState({});
    const [itemsPerSection, setItemsPerSection] = useState(4);
    const [searchQuery, setSearchQuery] = useState(""); // Стейт для поиска
    const [showAmbassadors, setShowAmbassadors] = useState(false);

    // Фетчинг списка партнёров
    const fetchPartners = async () => {
        try {
            const partnersResponse = await fetch(`${API_BASE_URL}:8081/api/v1/partners/list`);
            if (!partnersResponse.ok) throw new Error("Не удалось загрузить партнёров");
            const partnersData = await partnersResponse.json();

            const userResponse = await fetch(`${API_BASE_URL}:8081/api/v1/ambassadors/partners_list`, {
                headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
            });

            if (!userResponse.ok) throw new Error("Ошибка при загрузке списка амбассадоров");
            const userData = await userResponse.json();

            // Создаём Set (множество) ID партнёров, где пользователь амбассадор
            const ambassadorSet = new Set(userData.ambassador_partners || []);

            // Добавляем флаг `isUserAmbassador` в каждый объект `partner`
            // TODO TODO TODO TODO TODO: Сделать так чтобы 2 отсортированных списка было, для быстрого поиска 
            const updatedPartners = partnersData.map((partner) => ({
                ...partner,
                isUserAmbassador: ambassadorSet.has(partner.id), // true/false
            }));

            setPartners(updatedPartners);
        } catch (err) {
            console.error("Ошибка при загрузке данных:", err);
        }
    };

    // Функция изменения статуса амбассадора
    const toggleStatus = async (id) => {
        try {
            const token = localStorage.getItem("accessToken");
            if (!token) {
                alert("Необходима авторизация!");
                return;
            }

            const response = await fetch(`${API_BASE_URL}:8081/api/v1/ambassadors/add`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({
                    partner_id: id
                })
            });

            if (!response.ok) {
                const errorData = await response.json();
                if (response.status === 409) {
                    alert("Вы уже являетесь амбассадором этого партнёра!");
                } else {
                    throw new Error(errorData.message || "Ошибка при обновлении статуса");
                }
                return;
            }

            // Обновляем список партнёров после успешного добавления
            await fetchPartners();
        } catch (err) {
            console.error("Ошибка при изменении статуса:", err);
            alert(err.message || "Произошла ошибка при обновлении статуса");
        }
    };

    // Определяем количество карточек в секции в зависимости от ширины экрана
    useEffect(() => {
        const updateItemsPerSection = () => {
            setItemsPerSection(window.innerWidth < 768 ? 3 : 4);
        };

        updateItemsPerSection();
        window.addEventListener("resize", updateItemsPerSection);

        return () => {
            window.removeEventListener("resize", updateItemsPerSection);
        };
    }, []);

    // Загружаем список партнёров при монтировании
    useEffect(() => {
        fetchPartners();
    }, []);

    // Фильтруем партнёров по `searchQuery`
    const filteredPartners = partners
        .filter((partner) => showAmbassadors ? partner.isUserAmbassador : true)
        .filter((partner) => partner.login.toLowerCase().includes(searchQuery.toLowerCase()));

    // Разделение массива партнёров на секции
    const sections = [];
    for (let i = 0; i < filteredPartners.length; i += itemsPerSection) {
        sections.push(filteredPartners.slice(i, i + itemsPerSection));
    }

    return (
        <div className={styles["container-m"]}>
            <div className={styles["container-c"]}>
                <div className={styles.container}>
                    <div className={styles["cont"]}>
                        {/* Дисконтная карта */}
                        <div>
                            <h1>Дисконтный промокод</h1>
                            <div className={styles.section}>
                                <div className={styles["discount-card"]}>
                                    <div className={styles.strip}>
                                        <span id="discount-code">kenzhe888</span>
                                    </div>
                                    <div className={styles["chip-container"]}>
                                        <img className={styles.chip} src={chipImage} alt="chip" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Фильтры */}
                        <div>
                            <h1>Мои фильтры</h1>
                            <div className={styles.section}>
                                <div className={styles["search-container"]}>
                                    <input
                                        type="text"
                                        placeholder="Поиск компании"
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                    />
                                    <button>🔍</button>
                                </div>
                                <button
                                    className={`${styles["status-button"]} ${showAmbassadors ? styles.active : ""}`}
                                    onClick={() => setShowAmbassadors((prev) => !prev)}
                                >
                                    {showAmbassadors ? "Показать всех партнёров" : "Список компании, где я Амбассадор"}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Секция для партнёров */}
                <div className={styles.section}>
                    <h2>{showAmbassadors ? "Ваши партнёрские компании" : "Компании на выбор, которых я могу продвигать и получать скидку"}</h2>
                    {sections.map((section, sectionIndex) => (
                        <div className={styles["partners-section"]} key={`section-${sectionIndex}`}>
                            {section.map((partner, index) => (
                                <div className={styles["partner-card"]} key={`partner-${sectionIndex}-${index}`}>
                                    <div className={styles["card-inner"]}>

                                        {/* Передняя сторона */}
                                        <div className={styles["card-front"]}>
                                            <img
                                                src={partner.companyLogoBase64
                                                    ? `data:image/png;base64,${partner.companyLogoBase64}`
                                                    : "https://via.placeholder.com/150"}
                                                alt={`Фото компании ${partner.login}`}
                                                className={styles["partner-logo"]}
                                            />
                                            <div className={styles["partner-card-content"]}>
                                                <span className={styles["partner-name"]}>{partner.login}</span>
                                                <span className={styles["partner-discount"]}>
                                                    Скидка {partner.discount}%
                                                </span>
                                            </div>
                                        </div>

                                        {/* Задняя сторона */}
                                        <div className={styles["card-back"]}>
                                            <h3 className={styles["partner-name"]}>{partner.login}</h3>
                                            <p className={styles["partner-description"]}>
                                                Краткая информация на карточке:
                                            </p>

                                            {/* Динамическая кнопка */}
                                            <button
                                                className={`${styles["partner-status-button"]} 
                                                            ${partner.status === "inactive" ? styles.disabled : ""}
                                                            ${partner.status === "active" ? styles.active : ""}
                                                            ${partner.isUserAmbassador ? styles.ambassador : ""}`}
                                                onClick={() => toggleStatus(partner.id)}
                                                disabled={partner.status === "inactive"}
                                            >
                                                {(() => {
                                                    switch (partner.status) {
                                                        case "active":
                                                            return partner.isUserAmbassador ? "Вы уже амбассадор" : "Стать амбассадором";
                                                        case "inactive":
                                                            return "Тариф неактивен";
                                                        default:
                                                            return "Стать амбассадором";
                                                    }
                                                })()}
                                            </button>

                                            {/* Отображение статуса компании */}
                                            <p className={styles["partner-description"]}>
                                                {(() => {
                                                    if (partner.status === "active") {
                                                        return (
                                                            <>
                                                                Статус: <strong>Тариф куплен компанией</strong>.
                                                                <br />
                                                                Дисконт активен.
                                                                <br />
                                                                {partner.isUserAmbassador
                                                                    ? "Вы уже амбассадор, наслаждайтесь своими привилегиями!"
                                                                    : "Готов принять амбассадоров."
                                                                }
                                                            </>
                                                        );
                                                    } else if (partner.status === "inactive") {
                                                        return (
                                                            <>
                                                                Статус: <strong>Тариф неактивен</strong>.
                                                                <br />
                                                                Амбассадоры пока не набираются.
                                                            </>
                                                        );
                                                    }
                                                    return "Неизвестный статус.";
                                                })()}
                                            </p>

                                            {/* 🔗 Блок ссылок */}
                                            <div className={styles["partner-links"]}>
                                                <a href={`/partners/${partner.id}`} className={styles["partner-link"]} target="_blank" rel="noopener noreferrer">
                                                    🔗 ad_SERVLET
                                                </a>
                                                <a href={partner.website || "#"} className={styles["partner-link"]} target="_blank" rel="noopener noreferrer">
                                                    🌍 Официальный сайт
                                                </a>
                                                <a href={partner.instagram || "#"} className={styles["partner-instagram"]} target="_blank" rel="noopener noreferrer">
                                                    <img src="/static/media/Instagram_logo.a12aff2b57b600b7b7dee8d0fc0f4a59.svg"
                                                        alt="Instagram Logo"
                                                        className={styles["instagram-logo"]}
                                                    />
                                                    Instagram
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>

                <div className={styles.section}>
                    <h2>Пример партнёров (моковые данные)</h2>
                    
                    <div className={styles["partners-section"]}>
                        {/* 🟢 Активный тариф, но мы НЕ амбассадор */}
                        <div className={styles["partner-card"]}>
                            <div className={styles["card-inner"]}>
                                <div className={styles["card-front"]}>
                                    <img src="https://via.placeholder.com/150" alt="Компания 1" className={styles["partner-logo"]} />
                                    <div className={styles["partner-card-content"]}>
                                        <span className={styles["partner-name"]}>Компания 1</span>
                                        <span className={styles["partner-discount"]}>Скидка 10%</span>
                                    </div>
                                </div>
                                <div className={styles["card-back"]}>
                                    <h3 className={styles["partner-name"]}>Компания 1</h3>
                                    <p className={styles["partner-description"]}>
                                        Статус: <strong>Тариф куплен компанией</strong>.
                                        <br />Дисконт активен. <br />Готов принять амбассадоров.
                                    </p>
                                    <button className={styles["partner-status-button"]}>
                                        Стать амбассадором
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* ✅ Активный тариф + мы амбассадор */}
                        <div className={styles["partner-card"]}>
                            <div className={styles["card-inner"]}>
                                <div className={styles["card-front"]}>
                                    <img src="https://via.placeholder.com/150" alt="Компания 2" className={styles["partner-logo"]} />
                                    <div className={styles["partner-card-content"]}>
                                        <span className={styles["partner-name"]}>Компания 2</span>
                                        <span className={styles["partner-discount"]}>Скидка 15%</span>
                                    </div>
                                </div>
                                <div className={styles["card-back"]}>
                                    <h3 className={styles["partner-name"]}>Компания 2</h3>
                                    <p className={styles["partner-description"]}>
                                        Статус: <strong>Вы уже амбассадор</strong>.
                                        <br />Наслаждайтесь своими привилегиями!
                                    </p>
                                    <button className={`${styles["partner-status-button"]} ${styles.ambassador}`}>
                                        Вы амбассадор
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* ⚪ Неактивный тариф */}
                        <div className={styles["partner-card"]}>
                            <div className={styles["card-inner"]}>
                                <div className={styles["card-front"]}>
                                    <img src="https://via.placeholder.com/150" alt="Компания 3" className={styles["partner-logo"]} />
                                    <div className={styles["partner-card-content"]}>
                                        <span className={styles["partner-name"]}>Компания 3</span>
                                        <span className={styles["partner-discount"]}>Скидка 5%</span>
                                    </div>
                                </div>
                                <div className={styles["card-back"]}>
                                    <h3 className={styles["partner-name"]}>Компания 3</h3>
                                    <p className={styles["partner-description"]}>
                                        Статус: <strong>Тариф неактивен</strong>.
                                        <br />Амбассадоры пока не набираются.
                                    </p>
                                    <button className={`${styles["partner-status-button"]} ${styles.disabled}`} disabled>
                                        Тариф неактивен
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Partners;