// ProductCard.jsx
import React, { useState, useRef } from "react";
import styles from './ProductCard.module.css';
import ProductModal from "./ProductModal";

const ProductCard = ({ product, handleBuyProduct, addToCart }) => {
    // Картинка/Видео
    const videoRef = useRef(null);
    const handleMouseEnter = () => {
        if (videoRef.current) {
            videoRef.current.currentTime = 0;
            videoRef.current.play().catch(error => console.log("Autoplay error:", error));
        }
    };
    const handleMouseLeave = () => {
        if (videoRef.current) {
            videoRef.current.pause();
            videoRef.current.currentTime = 0;
        }
    };

    // Модалька
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleInfoProduct = () => {
        setIsModalOpen(true); // открыть модалку
    };
    const closeModal = () => setIsModalOpen(false);

    // Слайдер
    product.media = [
        { type: "image", src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5CiYemU3YKQhAZ-j4AUWb8-mFCyGD2TOeFg&s" },
        { type: "video", src: "https://goods-vod.kwcdn.com/goods-video/d5b6769f7df86b575f8f934f7d4b7dd4e0ab0cff.f30.mp4" },
        { type: "image", src: "https://www.spot.uz/media/img/2023/10/cN7ajJ16965926451306_b.jpg" }
    ];

    return (
        <>
            <div
                className={styles.productCard}
                onClick={() => handleInfoProduct(product.id)}
            >
                {/* Блок с картинкой и видео */}
                <div
                    className={styles.one}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    style={{ backgroundImage: `url(${product.image})` }}
                >
                    <video ref={videoRef} src={product.video} muted loop />
                </div>

                {/* Название */}
                <div className={styles.two}>
                    <a>{product.name}</a>
                </div>

                {/* Цена и корзина */}
                <div className={styles.three}>
                    <div className={styles.three_a}>{product.price}</div>
                    <div className={styles.three_b}>{product.currency}</div>
                </div>

                {/* Описание */}
                <div className={styles.six}>{product.description}</div>

                {/* Наличие товара */}
                <div className={`${styles.four} ${product.available ? styles.available : styles.soldOut}`}>
                    {product.available ? "Available" : "Sold Out"}
                </div>

                {/* Город, страна, продавец */}
                <div className={styles.five}>
                    {product.city}, {product.country} - {product.seller}
                </div>

                <div className={styles.footer_card}>
                    {/* Кнопка покупки */}
                    {product.available && (
                        <button
                            className={styles.button}
                            onClick={(e) => {
                                e.stopPropagation(); // чтобы не срабатывал клик по карточке
                                handleInfoProduct(product.id);
                            }}
                        >
                            Посмотреть
                        </button>
                    )}

                    {/* Контейнер для просмотров и даты */}
                    <div className={styles.product_footer}>
                        <div className={styles.watchCount}> 👁 {product.watch_count}</div>
                        <div className={styles.publishedAt}>{product.published_at}</div>
                    </div>
                </div>
            </div>

            {isModalOpen && (
                <ProductModal
                    product={product}
                    onClose={closeModal}
                    addToCart={addToCart}
                />
            )}
        </>
    );
};

export default ProductCard;