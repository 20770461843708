import React, { useState } from "react";
import styles from "./AuthLogin.module.css";

/* 
Важно: 
TODO: Http Only Cookie для защиты от XSS атак
*/

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const AuthLogin = () => {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({
    login: "",
    password: "",
  });

  // Санитизация пользовательского ввода
  const sanitizeInput = (input) => {
    return input.trim().replace(/[<>]/g, '');
  };

  // Валидация полей перед отправкой
  const validateFields = () => {
    const newErrors = {};

    if (!login) {
      newErrors.login = "Логин обязателен";
    } else if (login.length < 3) {
      newErrors.login = "Логин должен быть не менее 3 символов";
    }

    if (!password) {
      newErrors.password = "Пароль обязателен";
    } else if (password.length < 3) {
      newErrors.password = "Пароль должен быть не менее 3 символов";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleLogin = async (e) => {
    e.preventDefault(); // Предотвращаем стандартное поведение формы

    console.log("Функция handleLogin вызвана");

    if (!validateFields()) {
      console.log("Валидация не пройдена", errors);
      alert(`Ошибки валидации:\n${Object.values(errors).filter(error => error).join('\n')}`);
      return;
    }

    console.log("Валидация пройдена, отправка запроса...");

    try {
      const sanitizedLogin = sanitizeInput(login);
      const sanitizedPassword = sanitizeInput(password);

      console.log("Санитизированные данные:", { login: sanitizedLogin, password: sanitizedPassword });

      const requestBody = {
        login: sanitizedLogin,
        password: sanitizedPassword,
      };

      const response = await fetch(`${API_BASE_URL}:8080/api/v1/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include", // Важно для работы с HttpOnly cookies
        body: JSON.stringify(requestBody),
      });

      console.log("Ответ от сервера получен", response);

      if (!response.ok) {
        let errorMessage = "Ошибка авторизации";

        try {
          const errorData = await response.json();
          console.error("Ошибка авторизации:", errorData);
          errorMessage = errorData.message || errorMessage;
        } catch (jsonError) {
          console.warn("Не удалось разобрать тело ответа:", jsonError);
        }

        throw new Error(errorMessage);
      }

      const data = await response.json();

      console.log("Данные от сервера:", data);

      if (!data || !data.accessToken) {
        throw new Error("Некорректный ответ сервера");
      }

      localStorage.setItem("accessToken", data.accessToken);
      console.log("Токен сохранен");
    } catch (error) {
      if (error instanceof TypeError && error.message === "Failed to fetch") {
        console.error("Ошибка сети: сервер недоступен или проблема с подключением");
        setErrors((prev) => ({
          ...prev,
          login: "Ошибка сети: сервер недоступен или проблема с подключением",
        }));
      } else {
        console.error("Ошибка во время логина:", error);
        setErrors((prev) => ({
          ...prev,
          login: error.message,
        }));
      }
    }
  };

  return (
    <div className={styles.container}>
      <h3>Вход</h3>
      <form className={styles.form}>
        <label htmlFor="loginSet">Логин:</label>
        <input
          type="text"
          id="loginSet"
          placeholder="Введите логин"
          value={login}
          maxLength="30"
          onChange={(e) => setLogin(e.target.value)}
          className={styles.input}
        />
        <div className={styles.fieldError} id="error-loginFrom">
          {errors.login}
        </div>

        <label htmlFor="passwordSet">Пароль:</label>
        <div style={{ position: "relative" }}>
          <input
            type="password"
            id="passwordSet"
            placeholder="Введите пароль"
            value={password}
            maxLength="30"
            onChange={(e) => setPassword(e.target.value)}
            className={styles.input}
          />
        </div>
        <div className={styles.error} id="passwordError">
          {errors.password}
        </div>

        <button type="button" id="loginButton" onClick={handleLogin} className={styles.button}>
          Войти
        </button>
      </form>
    </div>
  );
};

export default AuthLogin;