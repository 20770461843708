import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import styles from "./Detection.module.css"; // Изменено на CSS-модуль

import device from '../../assets/images/Detection/device_wifi.jpg';
import radarGif from '../../assets/gif/radar.gif';

const Detection = () => {
  const navigate = useNavigate();

  const connectedDevices = [
    { id: 1, mac: 'AA:BB:CC:DD:EE:01', name: 'iPhone', datetime: '2025-03-11 12:34' },
    { id: 2, mac: 'AA:BB:CC:DD:EE:02', name: 'Samsung', datetime: '2025-03-11 13:45' },
  ];
  
  const passedByDevices = [
    { id: 1, mac: 'FF:GG:HH:II:JJ:01', name: 'MacBook', datetime: '2025-03-11 14:20' },
  ];

  const [quantity, setQuantity] = useState(0);
  const [textInput, setTextInput] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  
  const handleIncrease = () => setQuantity(quantity + 1);
  const handleDecrease = () => { if (quantity > 0) setQuantity(quantity - 1); };
  const totalPrice = (quantity * 649000).toLocaleString('ru-RU', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  
  const handleFileUpload = (event) => {
    const files = Array.from(event.target.files);
    if (files.length + selectedFiles.length > 8) {
      alert("You can upload a maximum of 8 images.");
      return;
    }
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
  };

  const handleNavigate = () => {
    navigate('/personal_routers');
  };

  return (
    <div className={styles.containerMain}>
      <div className={styles.containerContent}>
        <div className={styles.container}>
          <h2>
            <div>
              Устройство ad_SERVLET
            </div>
          </h2>
          <div className={styles.block}>
            <div className={styles.img_div}>
              <h3 className={styles.title_antenna}>MikroTik 9333</h3>

              <div>
                <img
                  className={styles.device}
                  src={device}
                  alt="device"
                />
              </div>
              <div className={styles.counter}>
                <button onClick={handleDecrease}>-</button>
                <input
                  type="number"
                  value={quantity}
                  readOnly
                  min="0"
                />
                <button onClick={handleIncrease}>+</button>
              </div>
              <label>
                <button className={styles.buyButton}>Купить {totalPrice} тенге</button>
              </label>
              <a href="google.com" className={styles.documentation_antenna}>Читать документацию</a>
                        
              </div>

            <div className={styles.exportContent}>
              <h3>Export content</h3>
              <div className={styles.textAreaContainer}>
                <textarea
                  className={styles.text_input}
                  maxLength="80"
                  rows="4"
                  placeholder="Enter text (max 80 characters)..."
                  value={textInput}
                  onChange={(e) => setTextInput(e.target.value)}
                ></textarea>
              </div>

              <div className={styles.uploadSection}>
                <div
                  className={styles.uploadButton}
                  onClick={() => document.getElementById("file-input").click()}
                >
                  <span>Click to upload images (Max 8)</span>
                  <input
                    type="file"
                    id="file-input"
                    accept="image/*"
                    multiple
                    style={{ display: "none" }}
                    onChange={handleFileUpload}
                  />
                </div>
                <div className={styles.subExportContent}>
                  
                  <div className={styles.label_blocs}>
                    <label style={{ marginRight: '10px' }} className={styles.payment_labels}>
                      <label className={styles.switch}>
                        <input type="checkbox"></input>
                        <span className={styles.slider}></span>                    
                        <div className={styles.webcam}>Web camera</div>
                      </label>
                    </label>
                  </div>

                  <div className={styles.label_blocs}>
                    <label style={{ marginRight: '10px' }} className={styles.payment_labels}>
                    <button className={styles.payment_button}>Оплатить</button>
                    <div className={styles.payment_text}>
                      Тариф в месяц <br/> 6 734.00 тенге
                    </div>
                    </label>
                  </div>

                  <div className={styles.label_blocs}>
                    <label className={styles.payment_labels}>
                      <button className={styles.payment_button} onClick={handleNavigate}>Смотреть</button>                    
                      <div className={styles.payment_text}>
                        Пройти на <br/> персональный сайт
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <h2>Детекция WIFI</h2>
          <div className={styles.block}>
            <div className={styles.radarBackground}>
              <img 
                className={styles.radarContainer} 
                src={radarGif} 
                alt="Radar animation" 
              />
            </div>

            <div className={styles.gridContainer}>
              <div className={styles.table}>
                <h3>Подключились и увидели рекламу</h3>
                <table className={styles.inner_table}>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>MAC-адрес</th>
                      <th>Имя устройства</th>
                      <th>Дата/Время</th>
                    </tr>
                  </thead>
                  <tbody id="connected-list">
                    {connectedDevices.map((device) => (
                      <tr key={device.id}>
                        <td>{device.id}</td>
                        <td>{device.mac}</td>
                        <td>{device.name}</td>
                        <td>{device.datetime}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className={styles.table}>
                <h3>Прошли мимо, не подключались</h3>
                <table className={styles.table}>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>MAC-адрес</th>
                      <th>Имя устройства</th>
                      <th>Дата/Время</th>
                    </tr>
                  </thead>
                  <tbody id="passed-by-list">
                    {passedByDevices.map((device) => (
                      <tr key={device.id}>
                        <td>{device.id}</td>
                        <td>{device.mac}</td>
                        <td>{device.name}</td>
                        <td>{device.datetime}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Detection;
