const citiesData = {
  KAZAKHSTAN: [
    { value: "ALMATY", label: "Алматы" },
    { value: "ASTANA", label: "Астана" },
    { value: "SHYMKENT", label: "Шымкент" },
    { value: "KARAGANDA", label: "Караганда" },
    { value: "AKTOBE", label: "Актобе" }
  ],
  RUSSIA: [
    { value: "MOSCOW", label: "Москва" },
    { value: "SPB", label: "Санкт-Петербург" },
    { value: "NOVOSIBIRSK", label: "Новосибирск" },
    { value: "EKATERINBURG", label: "Екатеринбург" },
    { value: "KAZAN", label: "Казань" }
  ],
  BELARUS: [
    { value: "MINSK", label: "Минск" },
    { value: "GOMEL", label: "Гомель" },
    { value: "MOGILEV", label: "Могилев" },
    { value: "VITEBSK", label: "Витебск" },
    { value: "GRODNO", label: "Гродно" }
  ],
  UKRAINE: [
    { value: "KYIV", label: "Киев" },
    { value: "KHARKIV", label: "Харьков" },
    { value: "ODESA", label: "Одесса" },
    { value: "DNIPRO", label: "Днепр" },
    { value: "LVIV", label: "Львов" }
  ],
  AZERBAIJAN: [
    { value: "BAKU", label: "Баку" },
    { value: "GANJA", label: "Гянджа" },
    { value: "SUMQAYIT", label: "Сумгайыт" },
    { value: "MINGACHEVIR", label: "Мингечевир" },
    { value: "SHIRVAN", label: "Ширван" }
  ],
  ARMENIA: [
    { value: "YEREVAN", label: "Ереван" },
    { value: "GYUMRI", label: "Гюмри" },
    { value: "VANADZOR", label: "Ванадзор" },
    { value: "VAGHARSHAPAT", label: "Вагаршапат" },
    { value: "HRAZDAN", label: "Раздан" }
  ],
  GEORGIA: [
    { value: "TBILISI", label: "Тбилиси" },
    { value: "KUTAISI", label: "Кутаиси" },
    { value: "BATUMI", label: "Батуми" },
    { value: "RUSTAVI", label: "Рустави" },
    { value: "ZUGDIDI", label: "Зугдиди" }
  ],
  KYRGYZSTAN: [
    { value: "BISHKEK", label: "Бишкек" },
    { value: "OSH", label: "Ош" },
    { value: "JALAL_ABAD", label: "Джалал-Абад" },
    { value: "KARAKOL", label: "Каракол" },
    { value: "TOKMOK", label: "Токмок" }
  ],
  MOLDOVA: [
    { value: "CHISINAU", label: "Кишинев" },
    { value: "TIRASPOL", label: "Тирасполь" },
    { value: "BALTI", label: "Бельцы" },
    { value: "BENDER", label: "Бендеры" },
    { value: "RIBNITA", label: "Рыбница" }
  ],
  TAJIKISTAN: [
    { value: "DUSHANBE", label: "Душанбе" },
    { value: "KHUJAND", label: "Худжанд" },
    { value: "BOKHTAR", label: "Бохтар" },
    { value: "KULOB", label: "Куляб" },
    { value: "ISTARAVSHAN", label: "Истаравшан" }
  ],
  TURKMENISTAN: [
    { value: "ASHGABAT", label: "Ашхабад" },
    { value: "TURKMENABAT", label: "Туркменабат" },
    { value: "DASHOGUZ", label: "Дашогуз" },
    { value: "MARY", label: "Мары" },
    { value: "BALKANABAT", label: "Балканабад" }
  ],
  UZBEKISTAN: [
    { value: "TASHKENT", label: "Ташкент" },
    { value: "SAMARKAND", label: "Самарканд" },
    { value: "NAMANGAN", label: "Наманган" },
    { value: "ANDIJAN", label: "Андижан" },
    { value: "BUKHARA", label: "Бухара" }
  ],

  // Европейский Союз (ЕС)
  GERMANY: [
    { value: "BERLIN", label: "Берлин" },
    { value: "MUNICH", label: "Мюнхен" },
    { value: "HAMBURG", label: "Гамбург" }
  ],
  FRANCE: [
    { value: "PARIS", label: "Париж" },
    { value: "LYON", label: "Лион" },
    { value: "MARSEILLE", label: "Марсель" }
  ],
  ITALY: [
    { value: "ROME", label: "Рим" },
    { value: "MILAN", label: "Милан" },
    { value: "NAPLES", label: "Неаполь" }
  ],
  SPAIN: [
    { value: "MADRID", label: "Мадрид" },
    { value: "BARCELONA", label: "Барселона" },
    { value: "VALENCIA", label: "Валенсия" }
  ],
  POLAND: [
    { value: "WARSAW", label: "Варшава" },
    { value: "KRAKOW", label: "Краков" },
    { value: "WROCLAW", label: "Вроцлав" }
  ],
  NETHERLANDS: [
    { value: "AMSTERDAM", label: "Амстердам" },
    { value: "ROTTERDAM", label: "Роттердам" },
    { value: "UTRECHT", label: "Утрехт" }
  ],
  SWEDEN: [
    { value: "STOCKHOLM", label: "Стокгольм" },
    { value: "GOTHENBURG", label: "Гетеборг" },
    { value: "MALMO", label: "Мальмё" }
  ],
  AUSTRIA: [
    { value: "VIENNA", label: "Вена" },
    { value: "GRAZ", label: "Грац" },
    { value: "LINZ", label: "Линц" }
  ],
  BELGIUM: [
    { value: "BRUSSELS", label: "Брюссель" },
    { value: "ANTWERP", label: "Антверпен" },
    { value: "GHENT", label: "Гент" }
  ],
  CZECHIA: [
    { value: "PRAGUE", label: "Прага" },
    { value: "BRNO", label: "Брно" },
    { value: "OSTRAVA", label: "Острава" }
  ],
  PORTUGAL: [
    { value: "LISBON", label: "Лиссабон" },
    { value: "PORTO", label: "Порту" },
    { value: "COIMBRA", label: "Коимбра" }
  ],
  FINLAND: [
    { value: "HELSINKI", label: "Хельсинки" },
    { value: "TAMPERE", label: "Тампере" },
    { value: "TURKU", label: "Турку" }
  ],
  GREECE: [
    { value: "ATHENS", label: "Афины" },
    { value: "THESSALONIKI", label: "Салоники" },
    { value: "HERAKLION", label: "Ираклион" }
  ],
  DENMARK: [
    { value: "COPENHAGEN", label: "Копенгаген" },
    { value: "AARHUS", label: "Орхус" },
    { value: "ODENSE", label: "Оденсе" }
  ],
  IRELAND: [
    { value: "DUBLIN", label: "Дублин" },
    { value: "CORK", label: "Корк" },
    { value: "LIMERICK", label: "Лимерик" }
  ],
  HUNGARY: [
    { value: "BUDAPEST", label: "Будапешт" },
    { value: "DEBRECEN", label: "Дебрецен" },
    { value: "SZEGED", label: "Сегед" }
  ],
  SLOVAKIA: [
    { value: "BRATISLAVA", label: "Братислава" },
    { value: "KOSICE", label: "Кошице" },
    { value: "NITRA", label: "Нитра" }
  ],
  SLOVENIA: [
    { value: "LJUBLJANA", label: "Любляна" },
    { value: "MARIBOR", label: "Марибор" },
    { value: "CELJE", label: "Целе" }
  ],
  LATVIA: [
    { value: "RIGA", label: "Рига" },
    { value: "DAUGAVPILS", label: "Даугавпилс" },
    { value: "LIEPAJA", label: "Лиепая" }
  ],
  LITHUANIA: [
    { value: "VILNIUS", label: "Вильнюс" },
    { value: "KAUNAS", label: "Каунас" },
    { value: "KLAIPEDA", label: "Клайпеда" }
  ],
  ESTONIA: [
    { value: "TALLINN", label: "Таллин" },
    { value: "TARTU", label: "Тарту" },
    { value: "NARVA", label: "Нарва" }
  ],
  BULGARIA: [
    { value: "SOFIA", label: "София" },
    { value: "PLOVDIV", label: "Пловдив" },
    { value: "VARNA", label: "Варна" }
  ],
  ROMANIA: [
    { value: "BUCHAREST", label: "Бухарест" },
    { value: "CLUJ", label: "Клуж-Напока" },
    { value: "TIMISOARA", label: "Тимишоара" }
  ],
  CROATIA: [
    { value: "ZAGREB", label: "Загреб" },
    { value: "SPLIT", label: "Сплит" },
    { value: "RIJEKA", label: "Риека" }
  ],
  LUXEMBOURG: [
    { value: "LUXEMBOURG_CITY", label: "Люксембург" }
  ],

  // Развитые страны (G7 и ключевые регионы)
  USA: [
    { value: "NEW_YORK", label: "Нью-Йорк" },
    { value: "LOS_ANGELES", label: "Лос-Анджелес" },
    { value: "CHICAGO", label: "Чикаго" }
  ],
  CANADA: [
    { value: "TORONTO", label: "Торонто" },
    { value: "VANCOUVER", label: "Ванкувер" },
    { value: "MONTREAL", label: "Монреаль" }
  ],
  UNITED_KINGDOM: [
    { value: "LONDON", label: "Лондон" },
    { value: "MANCHESTER", label: "Манчестер" },
    { value: "BIRMINGHAM", label: "Бирмингем" }
  ],
  AUSTRALIA: [
    { value: "SYDNEY", label: "Сидней" },
    { value: "MELBOURNE", label: "Мельбурн" },
    { value: "BRISBANE", label: "Брисбен" }
  ],
  NEW_ZEALAND: [
    { value: "AUCKLAND", label: "Окленд" },
    { value: "WELLINGTON", label: "Веллингтон" },
    { value: "CHRISTCHURCH", label: "Крайстчерч" }
  ],
  JAPAN: [
    { value: "TOKYO", label: "Токио" },
    { value: "OSAKA", label: "Осака" },
    { value: "KYOTO", label: "Киото" }
  ],
  SOUTH_KOREA: [
    { value: "SEOUL", label: "Сеул" },
    { value: "BUSAN", label: "Пусан" },
    { value: "INCHEON", label: "Инчхон" }
  ],
  SINGAPORE: [
    { value: "SINGAPORE_CITY", label: "Сингапур" }
  ],
  ISRAEL: [
    { value: "TEL_AVIV", label: "Тель-Авив" },
    { value: "JERUSALEM", label: "Иерусалим" },
    { value: "HAIFA", label: "Хайфа" }
  ],
  UNITED_ARAB_EMIRATES: [
    { value: "DUBAI", label: "Дубай" },
    { value: "ABU_DHABI", label: "Абу-Даби" },
    { value: "SHARJAH", label: "Шарджа" }
  ],
  TURKEY: [
    { value: "ISTANBUL", label: "Стамбул" },
    { value: "ANKARA", label: "Анкара" },
    { value: "IZMIR", label: "Измир" }
  ]
};

export default citiesData;
