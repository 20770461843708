import React, { useState } from 'react';
import ChatSidebar from './ChatSidebar';
import ChatWindow from './ChatWindow';
import styles from './ChatPage.module.css';

const ChatPage = () => {
    const [selectedChat, setSelectedChat] = useState(null);

    return (
        <div className={styles.chatPage}>
          <div className={styles.chatContainer}>
            <ChatSidebar onSelectChat={setSelectedChat} selectedChat={selectedChat} />
            <ChatWindow chat={selectedChat} />
          </div>
        </div>
      );      
};

export default ChatPage;