import { useImperativeHandle, forwardRef, useState } from "react";
import Notification from "../Notification/Notification";
import styles from "../Notification/Notification.module.css";

let id = 0;

const NotificationContainer = forwardRef((props, ref) => {
    const [notifications, setNotifications] = useState([]);

    const addNotification = (message, duration = 4000) => {
        const currentId = id++;
        setNotifications((prev) => [...prev, { id: currentId, message, duration }]);
        setTimeout(() => {
            setNotifications((prev) => prev.filter((n) => n.id !== currentId));
        }, duration);
    };

    useImperativeHandle(ref, () => addNotification);

    return (
        <div className={styles.notificationContainer}>
            {notifications.map((n) => (
                <Notification key={n.id} message={n.message} duration={n.duration} />
            ))}
        </div>
    );
});

export const notify = (message, duration = 4000) => {
    if (typeof window.__addNotif === "function") {
        window.__addNotif(message, duration);
    } else {
        console.warn("❗ Notification system is not ready");
    }
};
export default NotificationContainer;