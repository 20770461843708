import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import styles from "./Navbar.module.css";
import adServletLogo from "../../assets/svg/ad_servlet_logo.svg";

export default function Navbar() {
    const [menuOpen, setMenuOpen] = useState(false);
    const logoRef = useRef(null);
    const [logoStyles, setLogoStyles] = useState({});

    const toggleMenu = () => {
        setMenuOpen((prev) => !prev);
    };

    useEffect(() => {
        const updatePositions = () => {
            const logo = logoRef.current;
            const footer = document.getElementById("page-footer"); // Ищем футер в DOM

            if (!logo || !footer) return;

            const logoRect = logo.getBoundingClientRect();
            const footerRect = footer.getBoundingClientRect();

            return {
                logoInitialTop: logoRect.top + window.pageYOffset,
                logoHeight: logo.offsetHeight,
                footerTop: footerRect.top + window.pageYOffset,
            };
        };

        let { logoInitialTop, logoHeight, footerTop } = updatePositions() || {};

        const handleScroll = () => {
            if (!logoInitialTop || !logoHeight || !footerTop) return;
            
            const scrollY = window.pageYOffset;

            if (scrollY >= logoInitialTop && scrollY + logoHeight < footerTop) {
                setLogoStyles({ position: "fixed", top: "0px" });
            } else if (scrollY + logoHeight >= footerTop) {
                setLogoStyles({ position: "absolute", top: `${footerTop - logoHeight}px` });
            } else {
                setLogoStyles({ position: "static" });
            }
        };

        const observer = new MutationObserver(() => {
            const positions = updatePositions();
            if (positions) {
                logoInitialTop = positions.logoInitialTop;
                logoHeight = positions.logoHeight;
                footerTop = positions.footerTop;
            }
        });

        observer.observe(document.body, { childList: true, subtree: true });

        window.addEventListener("scroll", handleScroll);
        window.addEventListener("resize", () => {
            const positions = updatePositions();
            if (positions) {
                logoInitialTop = positions.logoInitialTop;
                logoHeight = positions.logoHeight;
                footerTop = positions.footerTop;
            }
        });

        handleScroll();

        return () => {
            observer.disconnect();
            window.removeEventListener("scroll", handleScroll);
            window.removeEventListener("resize", updatePositions);
        };
    }, []);

    return (
        <div>
            <div className={styles["navbar-contain"]}>
                <div className={`${styles.burger} ${menuOpen ? styles.open : ""}`} onClick={toggleMenu}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <header className={`${styles["navbar-header"]} ${menuOpen ? styles.open : ""}`}>
                    <nav>
                        <ul className={styles["navbar-mainUl"]}>
                            <li>
                                <Link to="/products">Главная</Link>
                                <ul>
                                <li><Link to="/apply">Подать</Link></li>
                                <li><Link to="/advertisements">Социальное объявление</Link></li>
                                <li><Link to="/learning">Обучение</Link></li>
                                <li><Link to="/courier">Курьер меню</Link></li>
                                <li><Link to="/chitchat">Чат</Link></li>
                                </ul>
                            </li>
                            <li>
                                <Link to="#">Продукты</Link>
                                <ul>
                                    <li><Link to="/mailing">Рассылки</Link></li>
                                    <li><Link to="/ai_chatbot">ИИ чат бот</Link></li>
                                    <li><Link to="/ai_caller">ИИ бот прозвонов</Link></li>
                                    <li><Link to="/detection">Детекция WIFI</Link></li>
                                </ul>
                            </li>
                            <li>
                                <Link to="/cashback">Кешбэки</Link>
                                <ul>
                                    <li><Link to="/analytics">Аналитика продаж</Link></li>
                                    <li><Link to="/dynamics">Динамика продаж</Link></li>
                                    <li><Link to="/partners">Юридические партнёры</Link></li>
                                </ul>
                            </li>
                            <li>
                                <Link to="/balance">Счёт</Link>
                            </li>
                            <li>
                                <Link to="#">Конфигурация</Link>
                            </li>
                        </ul>
                    </nav>
                </header>

                <div className={styles["navbar-logoContainer"]}>
                    <img 
                        src={adServletLogo} 
                        alt="Ad Servlet Logo" 
                        ref={logoRef} 
                        className={styles["navbar-logo"]} 
                        style={{ ...logoStyles, left: "initial", right: "initial" }} 
                    />
                </div>
            </div>
        </div>
    );
}