import React from "react";
import styles from './Footer.module.css';

import facebookLogo from '../../assets/svg/Facebook_Logo.svg';
import instagramLogo from '../../assets/svg/Instagram_logo.svg';
import twitterLogo from '../../assets/svg/twitter-logo.svg';

export default function Footer() {
  return (
    <footer id="page-footer" className={styles.footer}>
      <div className={styles.footerContainer}>
        <div className={styles.footerSection}>
          <h4>О нас</h4>
          <p>
            Внедрение новых технологий сегодня позволяет продвигаться к самым
            эффективным методам привлечения клиентской базы. Мы предоставляем
            платформу рекламного сервиса в различной области.
          </p>
        </div>
        <div className={styles.footerSection}>
          <h4>Контакты</h4>
          <p>Email: adservlet@mail.com</p>
          <p>Телефон: +7 (776) 999 52 56</p>
        </div>
        <div className={styles.footerSection}>
          <h4>Соцсети</h4>
          <div className={styles.socialLogo}>
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
              <img className={styles.socialLogs} src={facebookLogo} alt="Facebook Logo" />
            </a>
            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
              <img className={styles.socialLogs} src={instagramLogo} alt="Instagram Logo" />
            </a>
            <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
              <img className={styles.socialLogs} src={twitterLogo} alt="Twitter Logo" />
            </a>
          </div>
        </div>
      </div>
      <div className={styles.footerBottom}>
        <p>&copy; 2024 Все права защищены.</p>
      </div>
    </footer>
  );
}