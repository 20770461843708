import { useState, useRef } from "react";
import styles from './ProductModal.module.css';
import Notification from "../../components/Notification/Notification";
import { notify } from "../../components/NotificationContainer/NotificationContainer";
import ChatButton from '../../components/ChatButton/ChatButton';
import OrderFlow from "./OrderFlow";

export default function ProductModal({ product, onClose, addToCart }) {
    const [quantity, setQuantity] = useState(1);
    const [deliveryAddress, setDeliveryAddress] = useState('');
    const [showNotif, setShowNotif] = useState(false);
    const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
    const cartButtonRef = useRef(null);

    const media = product.media || [{ type: "image", src: product.image }];
    const currentMedia = media[currentMediaIndex];

    const handlePrev = () => {
        setCurrentMediaIndex((prev) =>
            prev === 0 ? media.length - 1 : prev - 1
        );
    };
    const handleNext = () => {
        setCurrentMediaIndex((prev) =>
            (prev + 1) % media.length
        );
    };

    const increaseQuantity = () => setQuantity((prev) => prev + 1);
    const decreaseQuantity = () => setQuantity((prev) => Math.max(1, prev - 1));

    const handleAddToCart = (e) => {
        e.stopPropagation();
        addToCart?.(product, quantity);
        notify(`🛒 ${product.name} — ${quantity} шт.`, 5000);

        // 🔽 Анимация ящика
        const box = document.createElement("div");
        box.textContent = "📦";
        box.className = styles.flyBox;
        document.body.appendChild(box);

        const from = e.currentTarget.getBoundingClientRect();
        const to = document.querySelector(".cart-float-button")?.getBoundingClientRect();

        if (to) {
            box.style.left = `${from.left + from.width / 2}px`;
            box.style.top = `${from.top + from.height / 2}px`;

            requestAnimationFrame(() => {
                box.style.transform = `translate(${to.left - from.left}px, ${to.top - from.top}px) scale(0.3)`;
                box.style.opacity = "0";
            });

            setTimeout(() => {
                document.body.removeChild(box);
            }, 1000);
        }

        // 🔽 Показываем уведомление
        setShowNotif(true);
    };

    // Адрес в CartModel 
    const [addressError, setAddressError] = useState(false);
    const handlePayment = () => {
        if (!deliveryAddress.trim()) {
            notify("❗ Введите адрес доставки", 4000);
            setAddressError(true);
            setTimeout(() => setAddressError(false), 1000); // Удалить ошибку через 1 секунду
            return;
        }

        notify(`✅ Заявка оформлена: ${product.name}, ${quantity} шт.`, 4000);
        setDeliveryAddress("");
        // Продолжить обработку платежа, если адрес введен
        alert("Оплата выбранных товаров");
    };

    return (
        <div className={styles.modalOverlay} onClick={onClose}>
            <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
                <div className={styles.modalBody}>
                    {/* Медиа слева */}
                    <div className={styles.mediaWrapper}>
                        <button className={`${styles.sliderBtn} ${styles.left}`} onClick={handlePrev}>&lt;</button>

                        {currentMedia.type === "image" ? (
                            <img src={currentMedia.src} alt="product" className={styles.mediaItem} />
                        ) : (
                            <video
                                src={currentMedia.src}
                                className={styles.mediaItem}
                                muted
                                loop
                                autoPlay
                                controls
                            />
                        )}

                        <button className={`${styles.sliderBtn} ${styles.right}`} onClick={handleNext}>&gt;</button>
                    </div>

                    {/* Информация справа */}
                    <div className={styles.modalInfo}>
                        <h2>{product.name}</h2>

                        {/* Блок статуса */}
                        <OrderFlow product={product}/>

                        <p><strong>Цена:</strong> {product.price} {product.currency}</p>
                        <p><strong>Описание:</strong> {product.description || "—"}</p>
                        <p><strong>Наличие:</strong> {product.available ? "В наличии" : "Нет в наличии"}</p>
                        <p><strong>Продавец:</strong> +7 747 999 88 11</p>
                        <p><strong>Город:</strong> {product.city}</p>
                        <p><strong>Страна:</strong> {product.country}</p>
                        <p><strong>Просмотры:</strong> {product.watch_count || "—"}</p>
                        <p><strong>Дата публикации:</strong> {product.published_at || "—"}</p>

                        <div className={styles.quantityWrapper}>
                            <button className={styles.quantityBtn} onClick={decreaseQuantity}>−</button>
                            <div className={styles.quantityDisplay}>{quantity}</div>
                            <button className={styles.quantityBtn} onClick={increaseQuantity}>+</button>
                        </div>

                        <input
                            type="text"
                            placeholder="Введите адрес доставки"
                            value={deliveryAddress}
                            onChange={(e) => setDeliveryAddress(e.target.value)}
                            className={`${styles.addressInput} ${addressError ? styles.addressInputError : ''}`}
                        />

                        <div className={styles.modalButtons}>
                            <button onClick={handlePayment} className={styles.modalClose}>💳 Отправить заявку</button>
                            <button
                                ref={cartButtonRef}
                                onClick={handleAddToCart}
                                className={styles.modalClose}
                            >
                                🛒 В корзину
                            </button>
                            <button onClick={onClose} className={styles.modalClose}>Закрыть</button>
                        </div>

                        <div className={styles.modalButtons}>
                        </div>

                        {showNotif && (
                            <Notification
                                message={`🛒 ${product.name} — ${quantity} шт.`}
                                duration={4000}
                                onClose={() => setShowNotif(false)}
                            />
                        )}

                        {/* Добавление кнопки чата с продавцом */}
                        <ChatButton userId={product.sellerId} />



                        <p>
                            <strong>🚚 Стоимость доставки рассчитывается отдельно.</strong><br />
                            Подайте заявку — продавец свяжется с вами, рассчитает цену и подберёт доставщика. После этого счёт будет выставлен в разделе <strong>«Моя корзина»</strong>.<br /><br />

                            💳 <strong>Вы сможете оплатить заказ или отказаться от него.</strong><br />
                            Оплата резервируется в системе и списывается только <em>после подтверждения</em> передачи товара доставщику.<br /><br />

                            ✅ <strong>Когда продавец подтвердит передачу, в вашей карточке заказа появится код.</strong><br />
                            Как только продавец отметит передачу — вы получите специальный <strong>код подтверждения</strong> в карточке заказа.<br /><br />

                            📦 Предъявите его доставщику при получении — и заберите товар.<br /><br />

                            <div className={styles.warningBlock}>
                                <p>
                                    ⚠️ <strong>Важно:</strong> перед получением обязательно проверьте <strong>наличие</strong> и <strong>состояние товара</strong>.<br />
                                    Если товар вас не устраивает или он повреждён — в карточке заказа нажмите кнопку <strong>«Детали»</strong>, где можно подать заявку на возврат средств в течение <strong>3 часов</strong> с момента получения.<br /><br />

                                    📸 Для подтверждения проблемы сфотографируйте товар и свяжитесь с продавцом через тот же раздел. Также, можете прикрепить фотографии и отправить <em>в этом же</em> разделе.<br /><br />

                                    ‼️ <strong>Важно:</strong> обе стороны — и продавец, и покупатель — обязаны сохранить все необходимые скриншоты и номер заявки (<strong>«Тикет»</strong>) в течение <strong>14 дней</strong> на случай спорных или проблемных ситуаций.
                                </p>
                            </div>

                            <div className={styles.warningBlock}>
                                <h4>Возврат и риски</h4>
                                <p>
                                    Средства за товар зачисляются продавцу в течение <strong>3 часов</strong> после ввода кода курьером.<br /><br />

                                    Если покупатель инициирует возврат, средства будут возвращены в течение <strong>3 часов</strong> — это гарантированное время,
                                    предоставленное для тщательной проверки полученного товара.<br /><br />

                                    Возврат осуществляется тем же способом, за счёт покупателя.<br /><br />

                                    Также товар можно вернуть в течение <strong>14 дней</strong> с момента получения, если он не был в эксплуатации и сохранил товарный вид.<br /><br />
                                    В этом случае покупатель и продавец обязаны самостоятельно урегулировать спор. Покупатель должен предоставить <strong>доказательства неисправности или несоответствия товара</strong>.<br /><br />
                                    Это соответствует <strong>Закону «О защите прав потребителей»</strong> — "Право потребителя на возврат или обмен товара надлежащего качества".

                                    Клиент получит свои средства обратно только после того, как продавец получит товар.<br />
                                    Если возврат не был оформлен в течение 3 часов, средства автоматически будут зачислены продавцу.<br /><br />

                                    <p><strong>Обратите внимание:</strong> система <code>ad_SERVLET</code> не несёт ответственности за возможные проблемы, возникшие в процессе передачи товара.<br />
                                        Все риски, связанные с упаковкой, передачей и безопасностью товара, лежат на продавце и клиенте при возврате.</p>
                                </p>
                            </div>
                        </p>


                    </div>
                </div>
            </div>
        </div>
    );
}