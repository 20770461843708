export const categoriesData = [
    { label: "Электроника", value: "electronics" },
    { label: "Хозяйство и Дом", value: "home" },
    { label: "Промышленность и Бизнес", value: "business" },
    { label: "Авто и транспорт", value: "transport" },
    { label: "Работа", value: "jobs" },
    { label: "Услуги", value: "services" },
    { label: "Животные", value: "animals" }
];

export const subCategoriesData = {
    electronics: [
        { label: "Бытовая техника", value: "home_appliances" },
        { label: "Холодильники и морозильники", value: "fridges" },
        { label: "Стиральные и сушильные машины", value: "washing_machines" },
        { label: "Плиты, духовки, варочные панели", value: "stoves" },
        { label: "Микроволновки", value: "microwaves" },
        { label: "Пылесосы", value: "vacuum_cleaners" },
        { label: "Кондиционеры и обогреватели", value: "air_conditioners" },
        { label: "Водонагреватели", value: "water_heaters" },
        { label: "Другая техника", value: "other_appliances" },
        { label: "Компьютеры", value: "computers" },
        { label: "Ноутбуки", value: "laptops" },
        { label: "ПК и комплектующие", value: "pc_parts" },
        { label: "Мониторы", value: "monitors" },
        { label: "Программное обеспечение", value: "software" },
        { label: "Аксессуары", value: "accessories" },
        { label: "Телефоны и аксессуары", value: "phones" },
        { label: "Смартфоны", value: "smartphones" },
        { label: "Кнопочные телефоны", value: "button_phones" },
        { label: "Планшеты", value: "tablets" },
        { label: "Чехлы, защитные стекла", value: "cases" },
        { label: "Зарядные устройства, кабели", value: "chargers" },
        { label: "Гарнитуры и наушники", value: "headsets" },
        { label: "Оргтехника", value: "office_equipment" },
        { label: "Принтеры и МФУ", value: "printers" },
        { label: "Сканеры", value: "scanners" },
        { label: "Картриджи и тонеры", value: "cartridges" },
        { label: "Проекторы", value: "projectors" },
        { label: "Другая техника", value: "other_electronics" }
    ],
    home: [
        { label: "Мебель (диваны, шкафы, кровати, стулья и т.д.)", value: "furniture" },
        { label: "Строительство и ремонт (инструменты, стройматериалы)", value: "construction" },
        { label: "Сад и огород (семена, саженцы, инструменты)", value: "garden" },
        { label: "Товары для дома (декор, посуда, текстиль)", value: "home_goods" }
    ],
    business: [
        { label: "Оборудование (станки, генераторы)", value: "equipment" },
        { label: "Сырье и материалы", value: "raw_materials" },
        { label: "Торговое оборудование", value: "trade_equipment" },
        { label: "Готовый бизнес", value: "ready_business" }
    ],
    transport: [
        { label: "Легковые авто", value: "cars" },
        { label: "Грузовики и спецтехника", value: "trucks" },
        { label: "Мотоциклы и скутеры", value: "motorcycles" },
        { label: "Запчасти и аксессуары", value: "car_parts" }
    ],
    jobs: [
        { label: "Вакансии", value: "vacancies" },
        { label: "Резюме", value: "resumes" }
    ],
    services: [
        { label: "Ремонт и строительство", value: "repair" },
        { label: "Курьерские услуги", value: "courier" },
        { label: "Обучение и репетиторство", value: "education" },
        { label: "Красота и здоровье", value: "beauty" }
    ],
    animals: [
        { label: "Домашние животные (кошки, собаки, грызуны, птицы, рептилии)", value: "pets" },
        { label: "Сельскохозяйственные животные (коровы, лошади, овцы, козы, свиньи, птица)", value: "farm_animals" },
        { label: "Рыбки и аквариумы", value: "fish" },
        { label: "Товары для животных (корм, аксессуары, вольеры, клетки)", value: "pet_goods" },
        { label: "Вязка и услуги", value: "breeding" }
    ]
};
