import { useEffect } from 'react';

const PersonalRouter = () => {
  useEffect(() => {
        const handleScroll = () => {
            console.log('📜 Скролл позиция:', window.pageYOffset);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div>

          <div>
          <h1>Личные маршрутизаторы</h1>
          <p>Здесь будет информация о персональных роутерах.</p>
          </div>
        </div>
    );
}

export default PersonalRouter;
