import React from 'react';
import { useState } from 'react';
import styles from './OrderShippingCard.module.css';
import ChatButton from '../../components/ChatButton/ChatButton';

const OrderShippingCard = ({ order, onUpload, onSend }) => {
    const [showTokenInfo, setShowTokenInfo] = useState(false);
    const token = 'f23c9a7e-0b4c-49a7-b5d7-7af6d3a59e29'; // Временно генерируем UUID

    const productPrice = parseFloat(order.amount || 0);
    const deliveryFee = parseFloat(order.invoice || 0);
    const commission = Math.round((productPrice + deliveryFee) * 0.05);
    const sellerAmount = productPrice;
    const courierAmount = deliveryFee;
    const total = productPrice + deliveryFee;

    const formatCurrency = (number) =>
        new Intl.NumberFormat('ru-RU', {
            style: 'currency',
            currency: 'KZT',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        }).format(number).replace('KZT', '₸');

    return (
        <div className={styles.card}>
            <div className={styles.header}>
                <p><strong>{order.login}</strong> — {order.phone}</p>
                <span className={styles.statusBadge}>📦 Готов к отправке</span>
            </div>

            <div className={styles.tokenDropdown}>
                <div
                    className={styles.tokenHeader}
                    onClick={() => setShowTokenInfo((prev) => !prev)}
                >
                    <p className={styles.tokenLine}>
                        <strong>🧾 Token курьера:</strong>
                        <code>{token}</code>
                        <button
                            className={styles.copyButton}
                            onClick={(e) => {
                                e.stopPropagation(); // чтобы не раскрывался dropdown при нажатии
                                navigator.clipboard.writeText(token);
                            }}
                        >
                            📋
                        </button>
                    </p>

                    <span className={styles.arrow}>
                        {showTokenInfo ? '▲' : '▼'}
                    </span>
                </div>


                {showTokenInfo && (
                    <div className={styles.tokenContent}>
                        <p>
                            Пожалуйста, вставьте данный токен в личный кабинет <code>ad_SERVLET</code> курьера.
                            Если курьер ещё не зарегистрирован, помогите ему создать учётную запись, указав ваш логин при регистрации.
                        </p>

                        <p>
                            После активации токена в личном кабинете <code>ad_SERVLET</code> в разделе <code>"Курьер"</code> отобразится страница с адресом доставки.
                            На этой странице будет поле для ввода <strong>кода подтверждения</strong>, который курьер получит от покупателя при передаче товара.
                        </p>

                        <p className={styles.tokenWarning}>
                            ⚠️ <strong>Важно:</strong> только после ввода этого кода средства за доставку будут автоматически перечислены курьеру.
                        </p>
                    </div>
                )}
            </div>

            <div className={styles.orderInfo}>
                <p>Товар: <strong>{order.productName}</strong></p>
                <p>Город: {order.city}</p>
                <p>Адрес: {order.address}</p>
                <p>Количество: {order.count}</p>
            </div>

            <div className={styles.paymentInfo}>
                💳 <strong>Общая сумма заказа:</strong> {formatCurrency(total)}<br /><br />
                🧾 <strong>Комиссия системы:</strong> {formatCurrency(commission)}<br />
                💰 <strong>Продавец получит:</strong> {formatCurrency(sellerAmount)}<br />
                🚚 <strong>Курьер получит:</strong> {formatCurrency(courierAmount)}
            </div>

            <div className={styles.actions}>
                <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => onUpload(e.target.files[0])}
                />
                <button onClick={onSend}>📤 Передан курьеру</button>
            </div>

            <div className={styles.note}>
                📸 Прикрепите фото товара или подтверждения передачи.<br />
                Деньги будут зачислены продавцу и курьеру после того, как курьер введёт код доставки от клиента.
            </div>

            <ChatButton userId={order.login} />
        </div>
    );
};

export default OrderShippingCard;