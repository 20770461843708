import { useState } from "react";
import styles from "./SavedTextContainer.module.css"; // адаптируй под себя
import ReactDOM from 'react-dom';

import OrderInvoiceCard from './OrderInvoiceCard';
import OrderWaitingCard from './OrderWaitingCard';
import OrderShippingCard from './OrderShippingCard';
import OrderSuccessCard from './OrderSuccessCard';
import OrderReturnCard from './OrderReturnCard';

const SavedTextContainer = ({ data, index, onMyOrders, onEdit, onDelete }) => {
    const [expanded, setExpanded] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [activeTab, setActiveTab] = useState('invoice'); // варианты: invoice, waiting, shipping, success, return

    const [orders, setOrders] = useState([
        {
            id: 1,
            login: 'user_alpha',
            phone: '+7 777 123 45 67',
            city: 'Алматы',
            address: 'ул. Панфилова, д. 10',
            count: 2,
            productName: 'Смартфон Galaxy A54',
            amount: 140000,
            currency: '₸',
            status: 'У продавца',
            invoice: '',
            file: null,
            createdAt: '2024-04-01'
        },
        {
            id: 2,
            login: 'client_beta',
            phone: '+7 701 999 88 55',
            city: 'Астана',
            address: 'пр. Назарбаева, д. 1',
            count: 1,
            productName: 'Планшет iPad Mini',
            amount: 80000,
            currency: '₸',
            status: 'Ожидает оплаты',
            invoice: '8000',
            file: null,
            createdAt: '2024-04-02'
        },
        {
            id: 3,
            login: 'buyer_gamma',
            phone: '+7 705 222 33 44',
            city: 'Шымкент',
            address: 'ул. Абая, д. 99',
            count: 1,
            productName: 'Наушники Sony WH-1000XM5',
            amount: 125000,
            currency: '₸',
            status: 'У курьера',
            invoice: '12000',
            file: 'photo.jpg',
            createdAt: '2024-04-03'
        },
        {
            id: 4,
            login: 'client_delta',
            phone: '+7 700 123 45 67',
            city: 'Караганда',
            address: 'пр. Мира, д. 20',
            count: 1,
            productName: 'Телевизор LG 55"',
            amount: 270000,
            currency: '₸',
            status: 'Сделка удалась',
            invoice: '15000',
            file: 'photo.jpg',
            createdAt: '2024-04-04'
        },
        {
            id: 5,
            login: 'return_case',
            phone: '+7 702 333 66 99',
            city: 'Павлодар',
            address: 'ул. Ленина, д. 5',
            count: 1,
            productName: 'Пылесос Xiaomi',
            amount: 75000,
            currency: '₸',
            status: 'Возврат товара',
            invoice: '9000',
            file: 'return_photo.jpg',
            createdAt: '2024-04-04'
        }
    ]);

    const handleSetInvoice = (id, value) => {
        setOrders(prev =>
            prev.map(order =>
                order.id === id
                    ? { ...order, invoice: value, status: 'Ожидает оплаты' }
                    : order
            )
        );
    };

    const handleMarkAsPaid = (index) => {
        setOrders(prev =>
            prev.map((order, i) =>
                i === index ? { ...order, status: 'У курьера' } : order
            )
        );
    };

    const handleUploadPhoto = (index, file) => {
        setOrders(prev =>
            prev.map((order, i) =>
                i === index ? { ...order, file } : order
            )
        );
    };

    const handleSend = (index) => {
        setOrders(prev =>
            prev.map((order, i) =>
                i === index ? { ...order, status: 'У покупателя' } : order
            )
        );
    };

    const handleComplete = (index) => {
        setOrders(prev =>
            prev.map((order, i) =>
                i === index ? { ...order, status: 'Сделка удалась' } : order
            )
        );
    };

    const handleReturn = (index) => {
        setOrders(prev =>
            prev.map((order, i) =>
                i === index ? { ...order, status: 'Возврат товара' } : order
            )
        );
    };

    // Кнопки блокнотов 
    const handleToggle = () => setExpanded((prev) => !prev);
    const handleMyOrdersClick = (e) => {
        e.stopPropagation();
        onMyOrders(index);
        setIsModalOpen((prev) => !prev);
    };
    const closeModal = () => setIsModalOpen(false);

    // Черновой
    const calculateCommission = (invoice) => {
        const amount = parseFloat(invoice);
        if (isNaN(amount)) return 0;
        return Math.round(amount * 0.05) + amount; // 5% комиссия, например
    };

    return (
        <div className={styles.savedTextContainer} onClick={handleToggle}>
            <div className={styles.headerRow}>
                <div className={styles.iconWrapper}>
                    <div className={styles.iconsLine}>
                        <span className={styles.bell_icon}>🔔</span> {/* TODO: Backend добавить обработку */}
                        <span className={styles.bell_icon}>❌</span>
                        <span className={styles.bell_icon}>✅</span>
                        <span className={styles.bell_icon}>🧡</span>
                        <span className={styles.bell_icon}>🔥</span>
                        <span className={styles.bell_icon}>👑</span>
                        <div className={styles.thunder_icon_wrapper}>
                            <span className={styles.thunder_icon_1}>⚡</span>
                            <span className={styles.thunder_icon_2}>⚡</span>
                            <span className={styles.thunder_icon_3}>⚡</span>
                        </div>

                        <span className={styles.notificationBadge}>3</span>
                    </div>
                </div>

                <p className={styles.previewTitle}>
                    {data.title || "Без заголовка"} — {data.price} {data.currency}
                </p>
            </div>

            {expanded && (
                <>
                    <div className={styles.savedTextContainerContent} >
                        <div className={styles.details}>
                            <p><strong>Категория:</strong> {data.category}</p>
                            <p><strong>Подкатегория:</strong> {data.subCategory}</p>
                            <p><strong>Описание:</strong> {data.description}</p>
                            <p><strong>Страна:</strong> {data.country}</p>
                            <p><strong>Город:</strong> {data.city}</p>
                            <p><strong>Телефоны:</strong> {data.phoneNumbers}</p>
                        </div>

                        <div className={styles.actionFooter}>
                            <button
                                className={styles.editButton}
                                onClick={handleMyOrdersClick}
                            >
                                📋 Мои заказы
                            </button>
                            <button
                                className={styles.editButton}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onEdit(index);
                                }}
                            >
                                ✏️ Редактировать
                            </button>
                            <button
                                className={styles.deleteButton}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onDelete(index);
                                }}
                            >
                                🗑️ Удалить
                            </button>
                        </div>
                    </div>
                    {isModalOpen && ReactDOM.createPortal(
                        <div className={styles.modalOverlay} onClick={closeModal}>
                            <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
                                {/* Инструкция в прокручиваемом блоке */}
                                <details className={styles.instructions}>
                                    <summary>📘 Инструкция по доставке и оформлению заказа</summary>
                                    <ol>
                                        <li>
                                            <strong>Поиск доставщика:</strong><br />
                                            Найдите доставщика, рассчитайте стоимость доставки и добавьте её к общей стоимости товара. Затем выставите покупателю счёт с учётом доставки. Если покупатель не отменит заказ и произведёт оплату — переходите к следующему этапу.
                                        </li>

                                        <li>
                                            <strong>Упаковка товара:</strong><br />
                                            Обязательно проконтролируйте упаковку товара. При необходимости — дополнительно упакуйте его, чтобы избежать повреждений в пути.
                                            Обратите внимание, что в большинстве случаев курьеры не следят за состоянием упаковки.
                                        </li>

                                        <li>
                                            <strong>Передача товара и регистрация доставщика:</strong><br />
                                            При передаче товара объясните доставщику, что он должен быть зарегистрирован в системе <code>ad_SERVLET</code>. Если он ещё не зарегистрирован — помогите ему пройти регистрацию, указав ваш ID в процессе.

                                            <ul>
                                                <li>📸 Также необходимо сфотографировать автомобиль доставщика с видимым государственным номером и лицо самого доставщика — это необходимо для подтверждения факта передачи товара.</li>
                                                <li>📎 Далее прикрепите эти две фотографии в карточке заявки и нажмите кнопку «Передано курьеру».</li>
                                            </ul>
                                        </li>

                                        <li>
                                            <strong>Получение оплаты курьером:</strong><br />
                                            После регистрации доставщику будет доступен личный кабинет. В разделе «Счёт» он найдёт поле с надписью <em>«Вставить код для получения средств»</em>.
                                            После успешной передачи товара получателю он должен ввести туда код, чтобы получить оплату.

                                            <p>Необходимо объяснить курьеру, что код для ввода он получит от получателя после передачи товара. Только после ввода этого кода деньги будут зачислены на его счёт. Убедитесь, что курьер понимает этот процесс и знает, где именно вводить код в личном кабинете.</p>
                                        </li>

                                        <li>
                                            <strong>Получение средств продавцом:</strong><br />
                                            Продавец получает оплату за товар после его успешной передачи покупателю. Подтверждением факта доставки служит код, введённый курьером в личном кабинете в разделе «Счёт».

                                            <p>Как только курьер введёт код, система автоматически зафиксирует доставку, и средства будут перечислены продавцу.</p>
                                        </li>

                                        <li>
                                            <strong>Возврат и риски:</strong><br />
                                            Средства за товар зачисляются продавцу в течение <strong>3 часов</strong> после ввода кода курьером.<br /><br />

                                            Если покупатель инициирует возврат, средства будут возвращены в течение <strong>3 часов</strong> — это гарантированное время,
                                            предоставленное для тщательной проверки полученного товара.<br /><br />

                                            Возврат осуществляется тем же способом, за счёт покупателя.<br /><br />

                                            Также товар можно вернуть в течение <strong>14 дней</strong> с момента получения, если он не был в эксплуатации и сохранил товарный вид.<br /><br />
                                            В этом случае покупатель и продавец обязаны самостоятельно урегулировать спор. Покупатель должен предоставить <strong>доказательства неисправности или несоответствия товара</strong>.<br /><br />
                                            Это соответствует <strong>Закону «О защите прав потребителей»</strong> — "Право потребителя на возврат или обмен товара надлежащего качества".

                                            Клиент получит свои средства обратно только после того, как продавец получит товар.<br />
                                            Если возврат не был оформлен в течение 3 часов, средства автоматически будут зачислены продавцу.<br /><br />

                                            <p><strong>Обратите внимание:</strong> система <code>ad_SERVLET</code> не несёт ответственности за возможные проблемы, возникшие в процессе передачи товара.<br />
                                                Все риски, связанные с упаковкой, передачей и безопасностью товара, лежат на продавце и клиенте при возврате.</p>
                                        </li>
                                    </ol>
                                </details>

                                <h2>Информация заявок</h2>

                                <div className={styles.tabMenu}>
                                    <button onClick={() => setActiveTab('invoice')} className={activeTab === 'invoice' ? styles.activeTab : ''}>💰 Заявка</button>
                                    <button onClick={() => setActiveTab('waiting')} className={activeTab === 'waiting' ? styles.activeTab : ''}>🕓 Оплата</button>
                                    <button onClick={() => setActiveTab('shipping')} className={activeTab === 'shipping' ? styles.activeTab : ''}>📦 Курьер</button>
                                    <button onClick={() => setActiveTab('success')} className={activeTab === 'success' ? styles.activeTab : ''}>✅ Завершено</button>
                                    <button onClick={() => setActiveTab('return')} className={activeTab === 'return' ? styles.activeTab : ''}>🔁 Возврат</button>
                                </div>

                                <div className={styles.tabContent}>
                                    {activeTab === 'invoice' && (
                                        orders
                                            .filter(o => o.status === 'У продавца')
                                            .map((order) => (
                                                <OrderInvoiceCard
                                                    key={order.id}
                                                    order={order}
                                                    onSetInvoice={(value) => handleSetInvoice(order.id, value)}
                                                />
                                            ))
                                    )}

                                    {activeTab === 'waiting' && (
                                        orders
                                            .filter(o => o.status === 'Ожидает оплаты')
                                            .map((order, i) => (
                                                <OrderWaitingCard
                                                    key={i}
                                                    order={order}
                                                    onMarkPaid={() => handleMarkAsPaid(i)}
                                                />
                                            ))
                                    )}

                                    {activeTab === 'shipping' && (
                                        orders
                                            .filter(o => o.status === 'У курьера')
                                            .map((order, i) => (
                                                <OrderShippingCard
                                                    key={i}
                                                    order={order}
                                                    onUpload={(file) => handleUploadPhoto(i, file)}
                                                    onSend={() => handleSend(i)}
                                                />
                                            ))
                                    )}

                                    {activeTab === 'success' && (
                                        orders
                                            .filter(o => o.status === 'Сделка удалась')
                                            .map((order, i) => (
                                                <OrderSuccessCard
                                                    key={i}
                                                    order={order}
                                                    onReturn={() => handleReturn(i)}
                                                />
                                            ))
                                    )}

                                    {activeTab === 'return' && (
                                        orders
                                            .filter(o => o.status === 'Возврат товара')
                                            .map((order, i) => (
                                                <OrderReturnCard
                                                    key={i}
                                                    order={order}
                                                    onConfirm={() => console.log("Возврат подтверждён")}
                                                />
                                            ))
                                    )}
                                </div>

                                <button
                                    className={styles.cancelButton}
                                    onClick={closeModal}
                                >
                                    Закрыть
                                </button>
                            </div>
                        </div>,
                        document.body
                    )}
                </>
            )}
        </div>
    );
};

export default SavedTextContainer;