import React, { useState, useEffect } from 'react';
import styles from './Analytics.module.css'; // Изменено на CSS-модуль

export default function Analytics() {
  // Состояния для статистики
  const [buyers, setBuyers] = useState(4);
  const [personalSales, setPersonalSales] = useState(3651239);
  const [salesFunnel, setSalesFunnel] = useState(10);
  const [funnelVolume, setFunnelVolume] = useState(36778112);
  const [activations, setActivations] = useState(['Активация 1', 'Активация 2', 'Активация 3']);
  const userId = 'yourUserId'; // Замените на реальный userId

  // Эффект для загрузки данных с бэкенда
  useEffect(() => {
    fetch(`http://localhost:8083/api/v1/${userId}`)
      .then(response => response.json())
      .then(data => {
        setBuyers(data.buyers);
        setPersonalSales(data.personalSales);
        setSalesFunnel(data.salesFunnel);
        setFunnelVolume(data.funnelVolume);
        setActivations(data.activations);
      })
      .catch(error => {
        console.error('Ошибка при загрузке данных:', error);
      });
  }, [userId]); // Зависимость от userId

  // Функция для добавления новой активации и отправки данных на сервер
  const addActivation = () => {
    const newActivation = `Активация ${activations.length + 1}`;
    const updatedActivations = [...activations, newActivation];
    setActivations(updatedActivations);

    // Отправляем данные на сервер
    fetch(`http://localhost:8083/api/v1/${userId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        buyers,
        personalSales,
        salesFunnel,
        funnelVolume,
        activations: updatedActivations
      })
    })
      .then(response => response.json())
      .then(data => {
        console.log('Данные успешно отправлены:', data);
      })
      .catch(error => {
        console.error('Ошибка при отправке данных:', error);
      });
  };

  return (
    <div className={styles.containerM}>
      <div className={styles.containerC}>
        <div className={styles.container}>
          <h1>Аналитика менеджера по продажам</h1>

          <div className={styles.stats}>
            <div className={styles.dropdown}>
              <label htmlFor="activations">Мои покупатели устройства с ежемесячной активацией 10%:</label>
              <select className={styles.selectActivation}id="activations">
                {activations.map((activation, index) => (
                  <option key={index} value={activation}>
                    {activation}
                  </option>
                ))}
              </select>
            </div>

            <p>Объем личных продаж: <span id="personal-sales">{personalSales.toLocaleString()}</span></p>
            <p>Динамика воронки личных продаж: <span id="sales-funnel">{salesFunnel}%; 65</span></p>
            <p>Общий объем воронки продаж: <span id="funnel-volume">{funnelVolume.toLocaleString()}</span></p>
          </div>


        </div>
      </div>
    </div>
  );
}
