import { useEffect, useState } from "react";
import styles from "./OrderFlow.module.css";

const OrderFlow = ({ product: propProduct }) => {
    const defaultProduct = {
        id: "prod123",
        name: "Apple Watch Ultra 2",
        price: 289000,
        currency: "₸",
    };
    const product = propProduct || defaultProduct;
    const [order, setOrder] = useState(null);

    const [payment, setPayment] = useState(null);
    const [delivery, setDelivery] = useState(null);

    // UX/UI
    const [isPaying, setIsPaying] = useState(false);
    const [showDetails, setShowDetails] = useState(false);

    const [status, setStatus] = useState("requested");
    const statuses = [
        { id: "requested", label: "Заявка" },
        { id: "paid", label: "Оплата" },
        { id: "shipped", label: "В пути" },
        { id: "delivered", label: "Доставлено" },
    ];

    // Эмуляция загрузки данных по API
    useEffect(() => {
        // Загружаем базовый заказ
        setOrder({
            id: "order_123",
            status: "paid",
            declined: false,
            returned: false,
        });

        // Загружаем оплату
        setPayment({
            invoiceIssued: true,
            invoiceId: "inv_456",
            amount: 289000,
            currency: "₸",
            isPaid: false,
        });

        // Загружаем доставку
        setDelivery({
            status: "en_route", // none | not_assigned | en_route
            wasDelivered: false,
            pickupCode: "754913",
            courier: {
                name: "Алихан",
                phone: "+7 777 123 45 67",
            },
            attachments: [
                {
                    type: "photo",
                    url: "/mock-package.jpg",
                    comment: "Упаковано плотно, без повреждений."
                }
            ]
        });
    }, []);

    const handlePayment = () => {
        setIsPaying(true);
        setTimeout(() => {
            setPayment((prev) => ({ ...prev, isPaid: true }));
            setOrder((prev) => ({ ...prev, status: "shipped" }));
            setStatus("shipped");
            setIsPaying(false);
        }, 1500);
    };

    const handleDecline = () => {
        setOrder((prev) => ({ ...prev, declined: true }));
    };

    if (!order || !payment || !delivery) return <p>Загрузка...</p>;

    return (
        <div className={styles.orderFlowWrapper}>
            <div className={styles.statusLine}>
                {statuses.map((s) => (
                    <div
                        key={s.id}
                        className={`${styles.statusStep} ${status === s.id ? styles.active : ""}`}
                        onClick={() => setStatus(s.id)}
                    >
                        {s.label}
                    </div>
                ))}
            </div>

            <div className={styles.statusContent}>
                {status === "requested" && !payment.invoiceIssued && (
                    <>
                        <h3>📝 Заявка оформлена</h3>
                        <p>Ожидайте подтверждения от продавца. После подтверждения будет выставлен счёт на оплату.</p>
                    </>
                )}

                {status === "requested" && payment.invoiceIssued && (
                    <>
                        <h3>📝 Счёт выставлен</h3>
                        <p>Продавец выставил счёт, вы можете перейти к оплате.</p>
                    </>
                )}

                {status === "paid" && order.declined && (
                    <p className={styles.declined}>Вы отказались от оплаты. Заказ отменён.</p>
                )}

                {status === "paid" && !order.declined && !payment.isPaid && (
                    <>
                        <h3>💰 Оплата</h3>
                        <p>Продавец выставил счёт. Подтвердите оплату или откажитесь.</p>
                        <p><strong>Сумма:</strong> {payment.amount} {payment.currency}</p>
                        <div className={styles.buttonRow}>
                            {isPaying ? (
                                <p>🔄 Обработка оплаты...</p>
                            ) : (
                                <button onClick={handlePayment} className={styles.acceptBtn}>✅ Оплатить</button>
                            )}
                            <button onClick={handleDecline} className={styles.rejectBtn}>❌ Отказаться</button>
                        </div>
                    </>
                )}

                {status === "paid" && payment.isPaid && (
                    <>
                        <h3>✅ Оплата подтверждена</h3>
                        <p>Ожидайте передачу товара в доставку.</p>
                    </>
                )}

                {status === "shipped" && delivery.status === "not_assigned" && (
                    <>
                        <h3>🚚 Ожидается передача товара в доставку</h3>
                        <p>Продавец ещё не передал товар курьеру.</p>
                    </>
                )}

                {status === "shipped" && delivery.status === "en_route" && !delivery.wasDelivered && (
                    <>
                        <h3>🚚 Товар в пути</h3>
                        <p>Курьер в пути. Ожидайте доставку.</p>

                        <div className={styles.expandSection}>
                            <p>🛠 Продавец может прикрепить фото отправки:</p>

                            <button onClick={() => setShowDetails(!showDetails)} className={styles.expandBtn}>
                                {showDetails ? "Скрыть детали ▲" : "Показать детали ▼"}
                            </button>

                            {showDetails && delivery.attachments.map((a, i) => (
                                <div key={i} className={styles.photoSection}>
                                    <p><strong>{a.type === "photo" ? "Фото упаковки:" : "Файл:"}</strong></p>
                                    <img src={a.url} alt="Доказательство" className={styles.packagePhoto} />
                                    {a.comment && <p><strong>Комментарий:</strong> {a.comment}</p>}
                                </div>
                            ))}
                        </div>

                        <div className={styles.courierBlock}>
                            <p><strong>Курьер:</strong> {delivery.courier.name}</p>
                            <p><strong>Телефон:</strong> {delivery.courier.phone}</p>
                            <p><strong>Код для получения:</strong></p>
                            <div className={styles.codeBox}>{delivery.pickupCode}</div>
                        </div>
                    </>
                )}

                {status === "delivered" && !delivery.wasDelivered && (
                    <>
                        <h3>📦 Товар ещё не доставлен</h3>
                        <p>Курьер не подтвердил вручение. Ожидаем подтверждение доставки.</p>
                    </>
                )}

                {status === "delivered" && delivery.wasDelivered && !order.returned && (
                    <>
                        <h3>📦 Товар доставлен</h3>
                        <p><strong>Название:</strong> {product.name}</p>
                        <p><strong>Цена:</strong> {product.price} {product.currency}</p>
                        <button className={styles.returnBtn}>🔄 Оформить возврат</button>
                    </>
                )}

                {status === "delivered" && order.returned && (
                    <>
                        <h3>🔄 Возврат оформлен</h3>
                        <p>Ожидайте подтверждение от продавца и инструкций.</p>
                    </>
                )}
            </div>
        </div>
    );
};

export default OrderFlow;