import React, { useState, useEffect } from 'react';
import styles from './Balance.module.css'; // Изменено на CSS-модуль

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Balance = () => {
    const [balances, setBalances] = useState({});
    const [selectedCurrency, setSelectedCurrency] = useState('Тенге'); // По умолчанию тенге
    const [recipient, setRecipient] = useState('');
    const [amount, setAmount] = useState('');

    const [transactions, setTransactions] = useState([]);

    const handleTransfer = async () => {
        if (!amount || !recipient) return alert('Введите сумму и логин получателя');
        if (balances[selectedCurrency] < amount) return alert('Недостаточно средств');

        try {
            const token = localStorage.getItem('accessToken');
            const response = await fetch(`${API_BASE_URL}:8083/api/v1/balance/transfer`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ recipient, amount, currency: selectedCurrency }),
            });

            if (!response.ok) throw new Error('Перевод не выполнен');

            alert('Перевод выполнен успешно!');
            setAmount('');
            setRecipient('');
        } catch (error) {
            console.error('Ошибка при переводе:', error);
        }
    };

    const handleWithdraw = () => {
        console.log("Вывод средств");
    };

    useEffect(() => {
        const fetchBalances = async () => {
            try {
                const token = localStorage.getItem('accessToken');
                if (!token) throw new Error('No access token found');

                const response = await fetch(`${API_BASE_URL}:8083/api/v1/balance/getBalances`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (!response.ok) throw new Error('Failed to fetch balances');

                const data = await response.json();
                console.log('Fetched balances:', data);
                console.log('Number of balances:', Array.isArray(data) ? data.length : 'Not an array');
        
                setBalances(data);
            } catch (error) {
                console.error('Error fetching balances:', error);
            }
        };

        fetchBalances();
    }, []); // Фетчится 1 раз при загрузке страницы

    useEffect(() => {
        const fetchTransactions = async () => {
            try {
                const token = localStorage.getItem('accessToken');
                if (!token) throw new Error('No access token found');
    
                const response = await fetch(`${API_BASE_URL}:8084/api/v1/transactions/user`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });
    
                if (!response.ok) throw new Error('Failed to fetch transactions');
    
                const data = await response.json();
                setTransactions(data);
            } catch (error) {
                console.error('Error fetching transactions:', error);
            }
        };
    
        fetchTransactions();
    }, []); // Фетчится 1 раз при загрузке страницы    

    return (
        <div className={styles.containerMain}>
            <div className={styles.containerContent}>
                {/* Левая панель */}
                <div className={styles.leftPanel}>
                    <h3>Баланс</h3>
                    <div className={styles.balanceBox}>
                        {balances[selectedCurrency]?.toLocaleString() || 0} {selectedCurrency}
                    </div>
                    <div className={styles.formGroup}>
                        <select 
                            className={styles.select}
                            value={selectedCurrency} 
                            onChange={(e) => setSelectedCurrency(e.target.value)}
                        >
                            {Object.keys(balances).map((currency) => (
                                <option key={currency} value={currency}>
                                    {currency}
                                </option>
                            ))}
                        </select>
                    </div>
                    
                    <div className={styles.formGroup}>
                        <input
                            className={styles.input}
                            id="form-text"
                            type="text"
                            placeholder="Укажите логин получателя"
                            value={recipient}
                            onChange={(e) => setRecipient(e.target.value)}
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <input
                            className={styles.input}
                            id="form-number"
                            type="number"
                            placeholder="Укажите сумму"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                        />
                    </div>
                    <button className={styles.button} id="transfer-btn" onClick={handleTransfer}>Перевод средств</button>
                    <button className={styles.button} id="withdraw-btn" onClick={handleWithdraw}>Вывод средств</button>
                </div>

                {/* Правая панель */}
                <div className={styles.rightPanel}>
                    <h3>Транзакции</h3>
                    <div className={styles.transactions}>
                        <table>
                            <thead>
                                <tr>
                                    <th>п/н</th>
                                    <th>тип</th>
                                    <th>рецепиент</th>
                                    <th>сумма</th>
                                    <th>валюта</th>
                                    <th>время</th>
                                    <th>дата</th>
                                </tr>
                            </thead>
                            <tbody>
                                {transactions.map((transaction, index) => (
                                    <tr key={transaction.id}>
                                        <td>{index + 1}</td>
                                        <td>{transaction.type}</td>
                                        <td>{transaction.toUser || "—"}</td>
                                        <td>{transaction.amount.toLocaleString()}</td>
                                        <td>{transaction.currency}</td>
                                        <td>{new Date(transaction.timestamp).toLocaleTimeString()}</td>
                                        <td>{new Date(transaction.timestamp).toLocaleDateString()}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Balance;