import React from 'react';
import styles from './StatusBlock.module.css';
import { formatCurrency, calculateTotal, calculateCommission } from './utils';  // Предполагается наличие утилит

const StatusBlock = ({ statusKey, order, from, time }) => {
    const className = from === 'seller' ? styles.fromSeller : styles.fromUser;

    // Функция для форматирования времени
    const formatTime = (isoString) => {
        return new Date(isoString).toLocaleTimeString('ru-RU', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        });
    };

    const renderCreated = () => (
        <div>
            🛒 <strong>Заявка создана</strong><br />
            Покупатель: {order.login}, Товар: {order.productName}
        </div>
    );

    const renderInvoice = () => {
        const total = calculateTotal(order.amount, order.invoice);
        const commission = calculateCommission(total);
        return (
            <div>
                <strong>💳 Счёт выставлен</strong><br />
                Покупатель: <strong>{order.login}</strong><br />
                Город: {order.city}<br />
                Товар: {order.productName} — {order.amount} {order.currency}<br />
                🚚 Доставка: {formatCurrency(order.invoice)}<br />
                🧾 Комиссия (5%): {formatCurrency(commission)}<br />
                💰 <strong>Итого к оплате: {formatCurrency(total)}</strong>
            </div>
        );
    };

    const renderWaiting = () => {
        const total = calculateTotal(order.amount, order.invoice);
        const commission = calculateCommission(total);
        return (
            <div>
                <strong>🕓 Ожидается оплата</strong><br />
                Покупатель: <strong>{order.login}</strong><br />
                Город: {order.city}<br />
                Товар: {order.productName} — {order.amount} {order.currency}<br />
                🚚 Доставка: {formatCurrency(order.invoice)}<br />
                🧾 Комиссия (5%): {formatCurrency(commission)}<br />
                💰 <strong>Итого: {formatCurrency(total)}</strong><br />
                <span style={{ color: '#888' }}>Оплата ожидается от покупателя. После подтверждения вы сможете передать товар.</span>
            </div>
        );
    };

    const renderShipping = () => {
        const productPrice = parseFloat(order.amount || 0);
        const deliveryFee = parseFloat(order.invoice || 0);
        const commission = Math.round((productPrice + deliveryFee) * 0.05);
        const sellerAmount = productPrice;
        const courierAmount = deliveryFee;
        const total = productPrice + deliveryFee;

        return (
            <div>
                <strong>📦 Товар передан курьеру</strong><br />
                Товар: <strong>{order.productName}</strong><br />
                Адрес доставки: {order.address}<br />
                💳 <strong>Общая сумма заказа:</strong> {formatCurrency(total)}<br />
                🧾 Комиссия: {formatCurrency(commission)}<br />
                💰 Продавец получит: {formatCurrency(sellerAmount)}<br />
                🚚 Курьер получит: {formatCurrency(courierAmount)}<br />
                <span>Ожидается ввод кода от покупателя для завершения сделки.</span>
            </div>
        );
    };

    const renderSuccess = () => {
        const invoice = parseFloat(order.invoice || 0);
        const commission = Math.round(invoice * 0.05);
        const received = invoice - commission;

        return (
            <div>
                ✅ <strong>Сделка завершена</strong><br />
                Покупатель: <strong>{order.login}</strong><br />
                Город: {order.city}<br />
                Товар: {order.productName} — {order.amount} {order.currency}<br />
                💳 <strong>Оплачено клиентом:</strong> {formatCurrency(invoice)}<br />
                🧾 Комиссия платформы: {formatCurrency(commission)}<br />
                💰 <strong>Продавец получил:</strong> {formatCurrency(received)}<br />
            </div>
        );
    };

    const renderReturn = () => {
        const invoice = parseFloat(order.invoice || 0);

        return (
            <div>
                🔁 <strong>Возврат оформлен</strong><br />
                Покупатель: <strong>{order.login}</strong><br />
                Город: {order.city}<br />
                Товар: {order.productName} — {order.amount} {order.currency}<br />
                💸 <strong>Сумма возврата:</strong> {invoice} {order.currency}<br />
                <span>Клиент инициировал возврат. Средства будут возвращены ему только после получения товара продавцом.</span>
            </div>
        );
    };

    return (
        <div className={`${styles.block} ${className}`}>
            {statusKey === 'created' && renderCreated()}
            {statusKey === 'invoice' && renderInvoice()}
            {statusKey === 'waiting' && renderWaiting()}
            {statusKey === 'shipping' && renderShipping()}
            {statusKey === 'success' && renderSuccess()}
            {statusKey === 'return' && renderReturn()}
            <span className={styles.timeStamp}>{formatTime(order.time)}</span> 
        </div>
    );
};

export default StatusBlock;