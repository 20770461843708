import React from 'react';
import styles from './Background.module.css';

export default function Background() {
  return (
    <div className={styles.videoContainer}>
      <iframe
        src="https://player.vimeo.com/video/1066929238?autoplay=1&loop=1&muted=1&background=1&controls=0&title=0&byline=0&portrait=0"
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        className={styles.backgroundVideo}
        title="Background Video"
      ></iframe>
    </div>
  );
}