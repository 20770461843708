import React from 'react';
import styles from './OrderWaitingCard.module.css';
import ChatButton from '../../components/ChatButton/ChatButton';

const OrderWaitingCard = ({ order, onMarkPaid }) => {
    return (
        <div className={styles.orderCard}>
            <div className={styles.header}>
                <div>
                    <p><strong>{order.login}</strong> — {order.phone}</p>
                    <p>{order.city}, {order.address}</p>
                    <p>📦 Товар: <strong>{order.productName}</strong></p>
                    <p>Количество: {order.count}</p>
                </div>
                <span className={styles.statusBadge}>🕓 Ожидаем оплату</span>
            </div>

            <div className={styles.invoiceInfo}>
                <p>💳 Счёт: <strong>{order.invoice || 0} ₸</strong></p>
                <p>После подтверждения оплаты вы сможете отправить товар покупателю.</p>
            </div>

            <button
                className={styles.actionButton}
                onClick={onMarkPaid}
            >
                ✅ Подтвердить оплату
            </button>

            <ChatButton userId={order.login} />
        </div>
    );
};

export default OrderWaitingCard;